import { IconProps } from './types';

const SearchInCircleIcon: React.FC<IconProps> = ({
    width,
    height = '100%',
    color = 'black',
}) => {
    return (
        <svg
            width={width}
            height={height}
            color={color}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="2.5 2 16 16">
            <g id="Search circle">
                <g id="Icon">
                    <path
                        d="M9.5 9C9.5 7.89543 10.3954 7 11.5 7C12.6046 7 13.5 7.89543 13.5 9C13.5 10.1046 12.6046 11 11.5 11C10.9474 11 10.4488 10.7772 10.0858 10.4142C9.72276 10.0512 9.5 9.55256 9.5 9Z"
                        fill={color}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.5 18C14.9183 18 18.5 14.4183 18.5 10C18.5 5.58172 14.9183 2 10.5 2C6.08172 2 2.5 5.58172 2.5 10C2.5 14.4183 6.08172 18 10.5 18ZM11.5 5C9.29086 5 7.5 6.79086 7.5 9C7.5 9.74138 7.70229 10.4364 8.05397 11.0318L5.79289 13.2929C5.40237 13.6834 5.40237 14.3166 5.79289 14.7071C6.18342 15.0976 6.81658 15.0976 7.20711 14.7071L9.46818 12.446C10.0636 12.7977 10.7586 13 11.5 13C13.7091 13 15.5 11.2091 15.5 9C15.5 6.79086 13.7091 5 11.5 5Z"
                        fill={color}
                    />
                </g>
            </g>
        </svg>
    );
};

export default SearchInCircleIcon;
