import { styled } from '@mui/material';

export const HeaderBlock = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
}));

export const LogoWrapper = styled('div')(() => ({
    cursor: 'pointer',
}));

export const Content = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
}));

const content = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const ContentR = styled('div')(() => ({ ...content }));

export const ContentL = styled('div')(() => ({
    ...content,
}));

export const Hr = styled('hr')(({ theme }) => ({
    width: '100%',
    borderBottom: theme.colors.dividerColor,
    height: '1px',
    opacity: '0.4',
    margin: '0px',
    zIndex: '99',
}));
