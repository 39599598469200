import useDebounce from 'hooks/useDebounce';
import { useFormValues } from 'hooks/useFormValues';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import AutocompleteInput from '../AutocompleteInput';
import { InputsStateProps } from '../ExtendedSearchMenu';
import { jobs } from './autocompleteValues';
import { AutocompleteWindow, StyledLi, StyledUl } from './JobTitleInput.styled';

interface Props {
    title: string;
    placeholder: string;
    inputFormKey: 'location' | 'job_title';
    searchHandler: () => void;
}

const JobTitleInput: React.FC<Props> = ({
    title,
    placeholder,
    inputFormKey,
    searchHandler,
}) => {
    const [isOnFocus, setOnFocus] = useState(false);
    const { setValue } = useFormContext<InputsStateProps>();

    const formValues = useFormValues();
    const jobTitleValue = formValues.job_title;
    const debouncedJobTitleValue = useDebounce(jobTitleValue, 300);

    const autocompleteHandler = (value: string) => {
        setValue(inputFormKey, value);
        setOnFocus(false);
    };

    const filteredValues = jobs.filter((job) =>
        job.toLowerCase().includes(debouncedJobTitleValue.toLowerCase())
    );

    return (
        <AutocompleteInput
            title={title}
            placeholder={placeholder}
            inputFormKey={inputFormKey}
            isOnFocus={isOnFocus}
            setOnFocus={setOnFocus}
            searchHandler={searchHandler}>
            {!!filteredValues.length && jobTitleValue.length >= 2 && (
                <AutocompleteWindow>
                    <StyledUl>
                        {filteredValues.map((value, idx) => (
                            <StyledLi
                                className="liPopper"
                                key={idx}
                                onClick={() => autocompleteHandler(value)}>
                                {value}
                            </StyledLi>
                        ))}
                    </StyledUl>
                </AutocompleteWindow>
            )}
        </AutocompleteInput>
    );
};

export default JobTitleInput;
