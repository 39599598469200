export const jobs = [
    'Web Developer',
    'Data Analyst',
    'Software Engineer',
    'Marketing Manager',
    'Graphic Designer',
    'Project Manager',
    'Accountant',
    'Human Resources Coordinator',
    'Social Media Specialist',
    'Sales Representative',
    'Product Manager',
    'Customer Service Representative',
    'UX Designer',
    'Operations Manager',
    'IT Support Specialist',
    'Content Writer',
    'Financial Analyst',
    'Business Development Manager',
    'Quality Assurance Engineer',
    'Public Relations Specialist',
    'Frontend Developer',
    'Backend Developer',
    'Mobile App Developer',
    'Data Scientist',
    'Database Administrator',
    'Information Security Analyst',
    'Network Administrator',
    'Cloud Solutions Architect',
    'Product Owner',
    'Scrum Master',
    'Full Stack Developer',
    'Systems Administrator',
    'DevOps Engineer',
    'Technical Writer',
    'UI Designer',
    'Video Editor',
    'Photographer',
    'Interior Designer',
    'Account Manager',
    'Brand Manager',
    'Market Research Analyst',
    'Business Analyst',
    'Supply Chain Manager',
    'Logistics Coordinator',
    'Warehouse Supervisor',
    'Manufacturing Engineer',
    'Mechanical Engineer',
    'Electrical Engineer',
    'Civil Engineer',
    'Architect',
    'Environmental Scientist',
    'Geologist',
    'Chemical Engineer',
    'Materials Scientist',
    'Nurse',
    'Physician',
    'Dentist',
    'Pharmacist',
    'Physical Therapist',
    'Occupational Therapist',
    'Speech-Language Pathologist',
    'Veterinarian',
    'Psychologist',
    'Counselor',
    'Social Worker',
    'Teacher',
    'Professor',
    'Librarian',
    'Museum Curator',
    'Artist',
    'Writer',
    'Editor',
    'Publisher',
    'Translator',
    'Interpreter',
    'Legal Assistant',
    'Paralegal',
    'Lawyer',
    'Judge',
    'Court Reporter',
    'Police Officer',
    'Firefighter',
    'Paramedic',
    'Security Guard',
    'Private Investigator',
    'Personal Trainer',
    'Nutritionist',
    'Chef',
    'Baker',
    'Bartender',
    'Waiter/Waitress',
    'Hotel Manager',
    'Travel Agent',
    'Flight Attendant',
    'Tour Guide',
    'Event Planner',
    'Wedding Planner',
    'Real Estate Agent',
    'Property Manager',
    'Construction Manager',
    'Landscaper',
    'Mechanic',
    'Electrician',
    'Plumber',
    'HVAC Technician',
    'Carpenter',
    'Welder',
    'Machinist',
    'Assembler',
    'Quality Control Inspector',
];
