import Constructor from '../Constructor';
import { ConstructorProps } from '../Constructor/Constructor';
import { Title, Wrapper } from './MobileContent.styled';

export interface MobileContentType {
    type: 'mobileContent';
    title: string;
    content: ConstructorProps[];
}

const MobileContent: React.FC<MobileContentType> = ({ content, title }) => {
    return (
        <Wrapper>
            <Title>{title}</Title>

            {content.map((el, idx) => (
                <Constructor key={idx} {...el} />
            ))}
        </Wrapper>
    );
};

export default MobileContent;
