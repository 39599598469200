import { IconProps } from './types';

const GlobalProfessionalsLogo: React.FC<IconProps> = ({
    width,
    height,
    isCursorPointer,
}) => {
    return (
        <svg
            width={width}
            height={height}
            style={{
                flexShrink: '0',
                cursor: isCursorPointer ? 'pointer' : 'auto',
            }}
            viewBox="0 0 155 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_17978_43212)">
                <path
                    d="M39.7935 38.9161C39.7935 39.6903 39.587 39.8968 38.8128 39.8968C37.2128 39.8452 35.6128 39.8452 34.0128 39.8968C33.1354 39.9484 32.929 39.5871 32.9806 38.8129C33.0322 37.6774 32.9806 36.4903 32.9806 35.1484C28.2838 39.9484 22.7096 40.4129 18.3225 39.7419V33.0839C24.8773 33.3935 29.5741 30.6581 32.4128 24.3613C31.6903 24.3613 29.729 24.3613 29.1612 24.3613C29.1612 24.3097 29.1612 24.3097 29.1612 24.2581C29.5225 23.5355 30.3999 21.2645 29.7806 17.6516C29.9354 17.6516 30.7096 17.6516 30.7096 17.6516C33.4451 17.6516 36.1806 17.6516 38.9161 17.6516C39.6386 17.6516 39.8451 17.9097 39.8451 18.5806C39.7935 25.3935 39.7935 32.1548 39.7935 38.9161Z"
                    fill="#243F80"
                />
                <path
                    d="M6.70958 34.5807C1.03216 29.8839 -1.85816 21.0065 1.34184 12.6968C4.43861 4.64518 12.2322 -0.361273 20.5935 1.71736e-05C31.2257 0.464533 37.1096 8.00002 38.5031 13.6258C38.6064 14.0903 38.1935 14.0903 37.9354 14.0903C36.0773 14.0903 34.2193 14.0903 32.3096 14.0903C31.7935 14.0903 31.4838 13.9355 31.2257 13.471C28.4386 8.56776 23.0709 5.9355 17.8064 6.81292C12.0257 7.79357 7.79345 11.9226 6.8128 17.7032C6.60635 18.7871 6.70958 19.871 6.70958 20.9549C6.76119 25.3936 6.70958 34.4258 6.70958 34.5807Z"
                    fill="#243F80"
                />
                <path
                    d="M29.7805 17.7033C29.7289 17.3936 29.6773 17.1356 29.5741 16.8259C28.4902 12.1291 22.916 8.56784 17.187 10.1678C13.6257 11.1485 10.8902 13.1098 10.0644 16.8775C9.70313 18.5291 9.80635 20.2324 9.80635 21.9356C9.70313 26.6324 9.75474 31.0711 9.70312 35.7678C9.70312 36.0775 9.70312 36.8001 9.70312 36.8001C11.3547 37.6775 13.1612 38.6065 15.1225 38.9678V28.9549C15.4322 29.0065 15.5354 29.0065 15.587 29.0582C20.6967 31.1227 26.0644 29.3678 28.9547 24.6195C28.9547 24.6195 29.0064 24.5162 29.1096 24.3614C29.5225 23.5872 30.3999 21.3162 29.7805 17.7033ZM19.7676 25.7033C16.5676 25.7033 13.9354 23.0711 13.9354 19.8711C13.9354 16.6711 16.5676 14.0388 19.7676 14.0388C22.9676 14.0388 25.5999 16.6711 25.5999 19.8711C25.5999 23.1227 23.0193 25.7033 19.7676 25.7033Z"
                    fill="#F1660B"
                />
                <path
                    d="M64.8256 11.0967C65.2901 11.0967 65.5998 11.2 65.8578 11.458C66.1159 11.7161 66.2191 12.0258 66.2191 12.4903C66.2191 13.2645 66.0643 13.9871 65.7546 14.7096C65.4449 15.3806 65.032 16 64.4643 16.5161C63.9481 17.0322 63.3288 17.4451 62.6062 17.7548C61.8836 18.0645 61.161 18.2193 60.3352 18.2193C59.5094 18.2193 58.7352 18.0645 58.0127 17.7548C57.2901 17.4451 56.6707 16.9806 56.103 16.4645C55.5352 15.8967 55.1223 15.2774 54.8127 14.5548C54.503 13.8322 54.3481 13.058 54.3481 12.2322C54.3481 11.4064 54.503 10.6322 54.8127 9.90963C55.1223 9.18705 55.5352 8.5677 56.103 7.99996C56.6707 7.43221 57.2901 7.01931 58.0127 6.70963C58.7352 6.39996 59.5094 6.24512 60.3352 6.24512C61.2127 6.24512 62.0385 6.39996 62.761 6.76125C63.5352 7.12254 64.1546 7.58705 64.7223 8.15479L62.761 9.80641C62.4514 9.49673 62.0901 9.23867 61.6772 9.08383C61.2643 8.92899 60.8514 8.82576 60.3352 8.82576C59.8707 8.82576 59.4062 8.92899 58.9933 9.08383C58.5804 9.23867 58.2191 9.49673 57.9094 9.80641C57.5998 10.1161 57.3417 10.4774 57.1869 10.8903C57.032 11.3032 56.9288 11.7677 56.9288 12.2322C56.9288 12.6967 57.032 13.1612 57.1869 13.5741C57.3417 13.9871 57.5998 14.3483 57.9094 14.658C58.2191 14.9677 58.5804 15.2258 58.9933 15.3806C59.4062 15.5354 59.8191 15.6387 60.3352 15.6387C60.6965 15.6387 61.0578 15.5871 61.3675 15.4838C61.6772 15.3806 61.9869 15.2258 62.2965 15.0193C62.6062 14.8129 62.8127 14.6064 63.0191 14.3483C63.2256 14.0903 63.3804 13.7806 63.5352 13.4709H60.7481V11.2H64.8256V11.0967Z"
                    fill="#062C59"
                />
                <path
                    d="M69.9868 17.8581H67.561V6.45166H69.9868V17.8581Z"
                    fill="#062C59"
                />
                <path
                    d="M71.019 13.8322C71.019 13.2128 71.1223 12.6451 71.3803 12.1289C71.6384 11.6128 71.9481 11.1483 72.361 10.7354C72.7739 10.3225 73.2384 10.0128 73.8061 9.80636C74.3739 9.59991 74.9416 9.44507 75.561 9.44507C76.1803 9.44507 76.7481 9.54829 77.3158 9.80636C77.8836 10.0128 78.3481 10.3741 78.761 10.7354C79.1739 11.1483 79.4836 11.6128 79.7416 12.1289C79.9997 12.6451 80.1029 13.2128 80.1029 13.8322C80.1029 14.4515 79.9997 15.0193 79.7416 15.5354C79.4836 16.0515 79.1739 16.516 78.761 16.9289C78.3481 17.3418 77.8836 17.6515 77.3158 17.858C76.7481 18.0644 76.1803 18.2193 75.561 18.2193C74.9416 18.2193 74.3739 18.116 73.8061 17.858C73.2384 17.6515 72.7739 17.2902 72.361 16.9289C71.9481 16.516 71.6384 16.0515 71.3803 15.5354C71.1223 14.9676 71.019 14.3999 71.019 13.8322ZM73.3416 13.7806C73.3416 14.0902 73.3932 14.3999 73.4965 14.658C73.5997 14.916 73.7545 15.1741 73.961 15.3806C74.1674 15.587 74.3739 15.7418 74.6319 15.8451C74.89 15.9483 75.1997 15.9999 75.4577 15.9999C75.7674 15.9999 76.0255 15.9483 76.2836 15.8451C76.5416 15.7418 76.7997 15.587 76.9545 15.3806C77.161 15.1741 77.3158 14.916 77.419 14.658C77.5223 14.3999 77.5739 14.0902 77.5739 13.7806C77.5739 13.4709 77.5223 13.2128 77.419 12.9547C77.3158 12.6967 77.161 12.4386 76.9545 12.2322C76.7481 12.0257 76.5416 11.8709 76.2836 11.7676C76.0255 11.6644 75.7158 11.6128 75.4577 11.6128C75.1481 11.6128 74.89 11.6644 74.6319 11.7676C74.3739 11.8709 74.1158 12.0257 73.961 12.2322C73.7545 12.4386 73.5997 12.6967 73.4965 12.9547C73.3932 13.2128 73.3416 13.5225 73.3416 13.7806Z"
                    fill="#062C59"
                />
                <path
                    d="M89.961 13.8323C89.961 14.4517 89.8578 15.0194 89.6513 15.5355C89.4449 16.0517 89.1352 16.5162 88.7739 16.9291C88.4126 17.342 87.9997 17.6517 87.4836 17.8581C87.0191 18.0646 86.4513 18.2194 85.9352 18.2194C85.4707 18.2194 85.0062 18.1162 84.5933 17.9613C84.1804 17.8065 83.7675 17.5484 83.4578 17.2388V17.8581H81.032V6.45166H83.4578V10.3742C83.8191 10.0646 84.1804 9.85811 84.5933 9.65166C85.0062 9.49682 85.4707 9.3936 85.9352 9.3936C86.503 9.3936 87.0191 9.49682 87.4836 9.75489C87.9481 9.96134 88.4126 10.3226 88.7739 10.6839C89.1352 11.0452 89.3933 11.5613 89.6513 12.0775C89.8578 12.6452 89.961 13.2129 89.961 13.8323ZM87.69 13.7807C87.69 13.471 87.6384 13.213 87.5352 12.9549C87.432 12.6968 87.2771 12.4388 87.0707 12.2323C86.8642 12.0259 86.6578 11.871 86.3997 11.7678C86.1417 11.6646 85.832 11.613 85.5739 11.613C85.2642 11.613 85.0062 11.6646 84.7481 11.7678C84.49 11.871 84.232 12.0259 84.0771 12.2323C83.8707 12.4388 83.7159 12.6968 83.6126 12.9549C83.5094 13.213 83.4578 13.5226 83.4578 13.7807C83.4578 14.0904 83.5094 14.4 83.6126 14.6581C83.7159 14.9162 83.8707 15.1742 84.0771 15.3807C84.2836 15.5871 84.49 15.742 84.7481 15.8452C85.0062 15.9484 85.3159 16 85.5739 16C85.8836 16 86.1417 15.9484 86.3997 15.8452C86.6578 15.742 86.9159 15.5871 87.0707 15.3807C87.2771 15.1742 87.432 14.9162 87.5352 14.6581C87.6384 14.4 87.69 14.0904 87.69 13.7807Z"
                    fill="#062C59"
                />
                <path
                    d="M90.6321 13.8322C90.6321 13.2128 90.7353 12.6451 90.9418 12.1289C91.1482 11.6128 91.4579 11.1483 91.8192 10.7354C92.1805 10.3225 92.5934 10.0128 93.1095 9.80636C93.574 9.59991 94.1418 9.44507 94.6579 9.44507C95.1224 9.44507 95.5869 9.54829 95.9998 9.70313C96.4127 9.85797 96.8256 10.116 97.1353 10.4257V9.75475H99.5611V17.9096H97.1353V17.2902C96.774 17.5999 96.4127 17.8064 95.9998 18.0128C95.5869 18.2193 95.1224 18.2709 94.6579 18.2709C94.0901 18.2709 93.574 18.1676 93.1095 17.9096C92.645 17.7031 92.1805 17.3418 91.8192 16.9806C91.4579 16.5676 91.1998 16.1031 90.9418 15.587C90.7353 14.9676 90.6321 14.3999 90.6321 13.8322ZM92.9547 13.7806C92.9547 14.0902 93.0063 14.3999 93.1095 14.658C93.2127 14.916 93.3676 15.1741 93.574 15.3806C93.7805 15.587 93.9869 15.7418 94.245 15.8451C94.503 15.9483 94.8127 15.9999 95.0708 15.9999C95.3805 15.9999 95.6385 15.9483 95.8966 15.8451C96.1547 15.7418 96.4127 15.587 96.5676 15.3806C96.774 15.1741 96.9289 14.916 97.0321 14.658C97.1353 14.3999 97.1869 14.0902 97.1869 13.7806C97.1869 13.4709 97.1353 13.2128 97.0321 12.9547C96.9289 12.6967 96.774 12.4386 96.5676 12.2322C96.3611 12.0257 96.1547 11.8709 95.8966 11.7676C95.6385 11.6644 95.3288 11.6128 95.0708 11.6128C94.7611 11.6128 94.503 11.6644 94.245 11.7676C93.9869 11.8709 93.7289 12.0257 93.574 12.2322C93.3676 12.4386 93.2127 12.6967 93.1095 12.9547C93.0063 13.2128 92.9547 13.5225 92.9547 13.7806Z"
                    fill="#062C59"
                />
                <path
                    d="M103.484 17.8581H101.058V6.45166H103.484V17.8581Z"
                    fill="#062C59"
                />
                <path
                    d="M60.7483 22.4517C61.3676 22.4517 61.9354 22.5549 62.3999 22.7613C62.8644 22.9678 63.2773 23.2775 63.587 23.5871C63.8966 23.9484 64.1547 24.3613 64.3095 24.7742C64.4644 25.2388 64.5676 25.6517 64.5676 26.1162C64.5676 26.5807 64.4644 27.0452 64.3095 27.4581C64.1547 27.9226 63.8966 28.2839 63.587 28.6452C63.2773 29.0065 62.8644 29.2646 62.3999 29.471C61.9354 29.6775 61.3676 29.7807 60.7483 29.7807H57.9612V33.7033H55.4321V24.1033C55.4321 23.0194 55.9999 22.4517 57.0837 22.4517H60.7483ZM57.9095 27.613H60.4902C60.7483 27.613 60.9547 27.5613 61.1612 27.5097C61.3676 27.4065 61.5225 27.3033 61.6257 27.2C61.7289 27.0452 61.8321 26.8904 61.9354 26.7355C61.987 26.5807 62.0386 26.3742 62.0386 26.2194C62.0386 26.0129 61.987 25.8581 61.9354 25.7033C61.8837 25.5484 61.7805 25.3936 61.6257 25.2388C61.5225 25.0839 61.3676 24.9807 61.1612 24.9291C60.9547 24.8259 60.7483 24.8259 60.4902 24.8259H57.9095V27.613Z"
                    fill="#062C59"
                />
                <path
                    d="M70.2449 27.7161C69.5223 27.7161 69.0062 27.9226 68.6449 28.2839C68.2836 28.6968 68.0772 29.2129 68.0772 29.8323V33.8065H65.6514V25.7032H68.0772V26.5807C68.2836 26.2194 68.6449 25.9097 69.0578 25.7032C69.4707 25.4968 69.8836 25.3936 70.2449 25.3936V27.7161Z"
                    fill="#062C59"
                />
                <path
                    d="M70.4514 29.8322C70.4514 29.2128 70.5546 28.6451 70.8127 28.1289C71.0708 27.6128 71.3805 27.1483 71.7934 26.7354C72.2063 26.3225 72.6708 26.0128 73.2385 25.8064C73.8063 25.5999 74.374 25.4451 74.9934 25.4451C75.6127 25.4451 76.1804 25.5483 76.7482 25.8064C77.3159 26.0128 77.7804 26.3741 78.1934 26.7354C78.6063 27.1483 78.9159 27.6128 79.174 28.1289C79.4321 28.6451 79.5353 29.2128 79.5353 29.8322C79.5353 30.4515 79.4321 31.0193 79.174 31.5354C78.9159 32.0515 78.6063 32.516 78.1934 32.9289C77.7804 33.3418 77.3159 33.6515 76.7482 33.858C76.1804 34.0644 75.6127 34.2193 74.9934 34.2193C74.374 34.2193 73.8063 34.116 73.2385 33.858C72.6708 33.6515 72.2063 33.2902 71.7934 32.9289C71.3805 32.516 71.0708 32.0515 70.8127 31.5354C70.6063 30.9676 70.4514 30.3999 70.4514 29.8322ZM72.774 29.7806C72.774 30.0902 72.8256 30.3999 72.9288 30.658C73.0321 30.916 73.1869 31.1741 73.3934 31.3806C73.5998 31.587 73.8063 31.7418 74.0643 31.8451C74.3224 31.9483 74.6321 31.9999 74.8901 31.9999C75.1998 31.9999 75.4579 31.9483 75.7159 31.8451C75.974 31.7418 76.2321 31.587 76.3869 31.3806C76.5934 31.1741 76.7482 30.916 76.8514 30.658C76.9546 30.3999 77.0063 30.0902 77.0063 29.7806C77.0063 29.4709 76.9546 29.2128 76.8514 28.9547C76.7482 28.6967 76.5934 28.4386 76.3869 28.2322C76.1804 28.0257 75.974 27.8709 75.7159 27.7676C75.4579 27.6644 75.1482 27.6128 74.8901 27.6128C74.5805 27.6128 74.3224 27.6644 74.0643 27.7676C73.8063 27.8709 73.5482 28.0257 73.3934 28.2322C73.1869 28.4386 73.0321 28.6967 72.9288 28.9547C72.8256 29.2128 72.774 29.5225 72.774 29.7806Z"
                    fill="#062C59"
                />
                <path
                    d="M85.7806 22.3484V24.2581H85.1613C84.5419 24.2581 84.2322 24.5677 84.2322 25.1871V25.7032H85.7806V27.6645H84.2322V33.8581H81.8064V27.6645H80.2581V25.7032H81.8064V25.2903C81.8064 23.329 82.7871 22.3484 84.7484 22.3484H85.7806Z"
                    fill="#062C59"
                />
                <path
                    d="M89.0321 30.5548C89.1869 31.0194 89.445 31.3807 89.8063 31.6387C90.1676 31.9484 90.6321 32.0516 91.0966 32.0516C91.4579 32.0516 91.7676 31.9484 92.0773 31.7936C92.3869 31.6387 92.645 31.3807 92.7999 31.1226L94.9676 31.8968C94.5547 32.5677 94.0386 33.1355 93.3676 33.5484C92.6966 33.9613 91.9224 34.1677 91.0966 34.1677C90.4773 34.1677 89.9095 34.0645 89.3418 33.8065C88.774 33.6 88.3095 33.2387 87.8966 32.8774C87.4837 32.4645 87.174 32 86.916 31.4839C86.6579 30.9677 86.5547 30.4 86.5547 29.7807C86.5547 29.1613 86.6579 28.5936 86.916 28.0774C87.174 27.5613 87.4837 27.0968 87.8966 26.6839C88.3095 26.271 88.774 25.9613 89.3418 25.7548C89.9095 25.5484 90.4773 25.3936 91.0966 25.3936C91.6644 25.3936 92.2321 25.4968 92.7482 25.7032C93.2644 25.9097 93.7289 26.1677 94.0902 26.529C94.5031 26.8903 94.8128 27.3032 95.0708 27.7677C95.3289 28.2323 95.4837 28.7484 95.5353 29.3161C95.587 29.6774 95.4837 29.9871 95.2773 30.2452C95.0708 30.4516 94.7095 30.5548 94.2966 30.5548H89.0321ZM93.0063 28.9548C92.8515 28.5419 92.5934 28.1807 92.2321 27.9226C91.8708 27.6645 91.4579 27.5097 91.045 27.5097C90.5805 27.5097 90.2192 27.6645 89.8579 27.9226C89.4966 28.1807 89.2386 28.5419 89.0837 28.9548H93.0063Z"
                    fill="#062C59"
                />
                <path
                    d="M96.2578 27.9742C96.2578 27.5097 96.361 27.0968 96.5675 26.7355C96.774 26.4258 97.032 26.1677 97.3417 25.9613C97.6514 25.7548 98.0127 25.6 98.374 25.5484C98.7352 25.4452 99.0965 25.3936 99.4578 25.3936C100.129 25.3936 100.748 25.5484 101.264 25.8065C101.78 26.0645 102.193 26.4258 102.555 26.8387L100.748 28.0258C100.593 27.8194 100.438 27.6645 100.232 27.5613C100.026 27.4581 99.8191 27.4065 99.561 27.4065C99.3546 27.4065 99.1998 27.4581 98.9933 27.5097C98.7869 27.5613 98.6836 27.7161 98.6836 27.9226C98.6836 28.129 98.7868 28.2839 99.0449 28.3871C99.2514 28.4903 99.5094 28.5936 99.8191 28.6968C100.18 28.8 100.49 28.9548 100.8 29.0581C101.109 29.2129 101.419 29.3677 101.677 29.5742C101.935 29.7807 102.142 30.0387 102.297 30.3484C102.451 30.6581 102.555 31.0194 102.555 31.4839C102.555 31.9484 102.451 32.3613 102.245 32.7226C102.038 33.0839 101.78 33.3419 101.471 33.5484C101.161 33.7548 100.8 33.9097 100.438 34.0129C100.077 34.1161 99.6643 34.1677 99.3546 34.1677C98.632 34.1677 97.961 34.0129 97.4449 33.7032C96.8772 33.3936 96.4127 33.0323 95.9998 32.5677L97.8062 31.2774C98.0127 31.4839 98.2191 31.6903 98.4772 31.8452C98.7352 32 98.9933 32.1032 99.3546 32.1032C99.5094 32.1032 99.7159 32.0516 99.9223 32C100.129 31.9484 100.232 31.7936 100.232 31.5871C100.232 31.3807 100.129 31.1742 99.8707 31.071C99.6127 30.9161 99.303 30.8129 98.8385 30.6581C98.5288 30.5548 98.2191 30.4 97.9094 30.2968C97.5998 30.1419 97.3417 29.9871 97.0836 29.7807C96.8256 29.5742 96.6707 29.3161 96.5159 29.0581C96.361 28.7484 96.2578 28.3871 96.2578 27.9742Z"
                    fill="#062C59"
                />
                <path
                    d="M103.432 27.9742C103.432 27.5097 103.535 27.0968 103.742 26.7355C103.948 26.4258 104.206 26.1677 104.516 25.9613C104.826 25.7548 105.187 25.6 105.548 25.5484C105.91 25.4452 106.271 25.3936 106.632 25.3936C107.303 25.3936 107.922 25.5484 108.439 25.8065C108.955 26.0645 109.368 26.4258 109.729 26.8387L107.922 28.0258C107.768 27.8194 107.613 27.6645 107.406 27.5613C107.2 27.4581 106.993 27.4065 106.735 27.4065C106.529 27.4065 106.374 27.4581 106.168 27.5097C105.961 27.5613 105.858 27.7161 105.858 27.9226C105.858 28.129 105.961 28.2839 106.219 28.3871C106.426 28.4903 106.684 28.5936 106.993 28.6968C107.355 28.8 107.664 28.9548 107.974 29.0581C108.284 29.2129 108.593 29.3677 108.851 29.5742C109.11 29.7807 109.316 30.0387 109.471 30.3484C109.626 30.6581 109.729 31.0194 109.729 31.4839C109.729 31.9484 109.626 32.3613 109.419 32.7226C109.213 33.0839 108.955 33.3419 108.645 33.5484C108.335 33.7548 107.974 33.9097 107.613 34.0129C107.251 34.1161 106.839 34.1677 106.529 34.1677C105.806 34.1677 105.135 34.0129 104.619 33.7032C104.051 33.3936 103.587 33.0323 103.174 32.5677L104.981 31.2774C105.187 31.4839 105.393 31.6903 105.651 31.8452C105.91 32 106.168 32.1032 106.529 32.1032C106.684 32.1032 106.89 32.0516 107.097 32C107.303 31.9484 107.406 31.7936 107.406 31.5871C107.406 31.3807 107.303 31.1742 107.045 31.071C106.787 30.9161 106.477 30.8129 106.013 30.6581C105.703 30.5548 105.393 30.4 105.084 30.2968C104.774 30.1419 104.516 29.9871 104.258 29.7807C104 29.5742 103.845 29.3161 103.69 29.0581C103.484 28.7484 103.432 28.3871 103.432 27.9742Z"
                    fill="#062C59"
                />
                <path
                    d="M110.761 23.2774C110.761 22.8645 110.916 22.5549 111.174 22.2452C111.484 21.9355 111.793 21.8323 112.206 21.8323C112.619 21.8323 112.929 21.9871 113.239 22.2452C113.548 22.5549 113.652 22.8645 113.652 23.2774C113.652 23.6903 113.497 24 113.239 24.3097C112.929 24.6194 112.619 24.7226 112.206 24.7226C111.793 24.7226 111.484 24.5678 111.174 24.3097C110.864 24.0516 110.761 23.6903 110.761 23.2774ZM110.968 25.7032H113.393V33.8581H110.968V25.7032Z"
                    fill="#062C59"
                />
                <path
                    d="M114.581 29.8322C114.581 29.2128 114.684 28.6451 114.942 28.1289C115.2 27.6128 115.51 27.1483 115.923 26.7354C116.335 26.3225 116.8 26.0128 117.368 25.8064C117.935 25.5999 118.503 25.4451 119.123 25.4451C119.742 25.4451 120.31 25.5483 120.877 25.8064C121.445 26.0128 121.91 26.3741 122.323 26.7354C122.735 27.1483 123.045 27.6128 123.303 28.1289C123.561 28.6451 123.664 29.2128 123.664 29.8322C123.664 30.4515 123.561 31.0193 123.303 31.5354C123.045 32.0515 122.735 32.516 122.323 32.9289C121.91 33.3418 121.445 33.6515 120.877 33.858C120.31 34.0644 119.742 34.2193 119.123 34.2193C118.503 34.2193 117.935 34.116 117.368 33.858C116.8 33.6515 116.335 33.2902 115.923 32.9289C115.51 32.516 115.2 32.0515 114.942 31.5354C114.684 30.9676 114.581 30.3999 114.581 29.8322ZM116.852 29.7806C116.852 30.0902 116.903 30.3999 117.006 30.658C117.11 30.916 117.264 31.1741 117.471 31.3806C117.677 31.587 117.884 31.7418 118.142 31.8451C118.4 31.9483 118.71 31.9999 118.968 31.9999C119.277 31.9999 119.535 31.9483 119.793 31.8451C120.052 31.7418 120.31 31.587 120.464 31.3806C120.671 31.1741 120.826 30.916 120.929 30.658C121.032 30.3999 121.084 30.0902 121.084 29.7806C121.084 29.4709 121.032 29.2128 120.929 28.9547C120.826 28.6967 120.671 28.4386 120.464 28.2322C120.258 28.0257 120.052 27.8709 119.793 27.7676C119.535 27.6644 119.226 27.6128 118.968 27.6128C118.658 27.6128 118.4 27.6644 118.142 27.7676C117.884 27.8709 117.626 28.0257 117.471 28.2322C117.264 28.4386 117.11 28.6967 117.006 28.9547C116.955 29.2128 116.852 29.5225 116.852 29.7806Z"
                    fill="#062C59"
                />
                <path
                    d="M130.426 29.4194C130.426 29.1613 130.374 28.9549 130.271 28.7484C130.168 28.542 130.064 28.3355 129.91 28.1807C129.755 28.0259 129.548 27.9226 129.342 27.8194C129.135 27.7162 128.929 27.6646 128.671 27.6646C128.413 27.6646 128.206 27.7162 128 27.8194C127.793 27.9226 127.587 28.0259 127.432 28.1807C127.277 28.3355 127.174 28.542 127.071 28.7484C126.968 28.9549 126.916 29.1613 126.916 29.4194V33.8065H124.49V25.7033H126.916V26.3226C127.226 26.0646 127.587 25.8581 128 25.7033C128.413 25.5484 128.826 25.4968 129.239 25.4968C129.703 25.4968 130.168 25.6001 130.632 25.7549C131.045 25.9613 131.458 26.2194 131.768 26.5291C132.077 26.8388 132.335 27.2517 132.542 27.6646C132.748 28.0775 132.8 28.542 132.8 29.0581V33.9097H130.374V29.4194H130.426Z"
                    fill="#062C59"
                />
                <path
                    d="M133.935 29.8322C133.935 29.2128 134.039 28.6451 134.245 28.1289C134.451 27.6128 134.761 27.1483 135.122 26.7354C135.484 26.3225 135.897 26.0128 136.413 25.8064C136.877 25.5999 137.445 25.4451 137.961 25.4451C138.426 25.4451 138.89 25.5483 139.303 25.7031C139.716 25.858 140.129 26.116 140.439 26.4257V25.7547H142.864V33.9096H140.439V33.2902C140.077 33.5999 139.716 33.8064 139.303 34.0128C138.89 34.2193 138.426 34.2709 137.961 34.2709C137.393 34.2709 136.877 34.1676 136.413 33.9096C135.948 33.7031 135.484 33.3418 135.122 32.9806C134.761 32.5676 134.503 32.1031 134.245 31.587C134.039 30.9676 133.935 30.3999 133.935 29.8322ZM136.258 29.7806C136.258 30.0902 136.309 30.3999 136.413 30.658C136.516 30.916 136.671 31.1741 136.877 31.3806C137.084 31.587 137.29 31.7418 137.548 31.8451C137.806 31.9483 138.116 31.9999 138.374 31.9999C138.684 31.9999 138.942 31.9483 139.2 31.8451C139.458 31.7418 139.716 31.587 139.871 31.3806C140.077 31.1741 140.232 30.916 140.335 30.658C140.439 30.3999 140.49 30.0902 140.49 29.7806C140.49 29.4709 140.439 29.2128 140.335 28.9547C140.232 28.6967 140.077 28.4386 139.871 28.2322C139.664 28.0257 139.458 27.8709 139.2 27.7676C138.942 27.6644 138.632 27.6128 138.374 27.6128C138.064 27.6128 137.806 27.6644 137.548 27.7676C137.29 27.8709 137.032 28.0257 136.877 28.2322C136.671 28.4386 136.516 28.6967 136.413 28.9547C136.309 29.2128 136.258 29.5225 136.258 29.7806Z"
                    fill="#062C59"
                />
                <path
                    d="M146.787 33.8581H144.361V22.4517H146.787V33.8581Z"
                    fill="#062C59"
                />
                <path
                    d="M147.922 27.9742C147.922 27.5097 148.026 27.0968 148.232 26.7355C148.439 26.4258 148.697 26.1677 149.006 25.9613C149.316 25.7548 149.677 25.6 150.039 25.5484C150.4 25.4452 150.761 25.3936 151.122 25.3936C151.793 25.3936 152.413 25.5484 152.929 25.8065C153.445 26.0645 153.858 26.4258 154.219 26.8387L152.413 28.0258C152.258 27.8194 152.103 27.6645 151.897 27.5613C151.69 27.4581 151.484 27.4065 151.226 27.4065C151.019 27.4065 150.864 27.4581 150.658 27.5097C150.451 27.5613 150.348 27.7161 150.348 27.9226C150.348 28.129 150.451 28.2839 150.709 28.3871C150.916 28.4903 151.174 28.5936 151.484 28.6968C151.845 28.8 152.155 28.9548 152.464 29.0581C152.774 29.2129 153.084 29.3677 153.342 29.5742C153.6 29.7807 153.806 30.0387 153.961 30.3484C154.116 30.6581 154.219 31.0194 154.219 31.4839C154.219 31.9484 154.116 32.3613 153.909 32.7226C153.703 33.0839 153.445 33.3419 153.135 33.5484C152.826 33.7548 152.464 33.9097 152.103 34.0129C151.742 34.1161 151.329 34.1677 151.019 34.1677C150.297 34.1677 149.626 34.0129 149.109 33.7032C148.542 33.3936 148.077 33.0323 147.664 32.5677L149.471 31.2774C149.677 31.4839 149.884 31.6903 150.142 31.8452C150.4 32 150.658 32.1032 151.019 32.1032C151.174 32.1032 151.38 32.0516 151.587 32C151.793 31.9484 151.897 31.7936 151.897 31.5871C151.897 31.3807 151.793 31.1742 151.535 31.071C151.277 30.9161 150.968 30.8129 150.503 30.6581C150.193 30.5548 149.884 30.4 149.574 30.2968C149.264 30.1419 149.006 29.9871 148.748 29.7807C148.49 29.5742 148.335 29.3161 148.18 29.0581C148.026 28.7484 147.922 28.3871 147.922 27.9742Z"
                    fill="#062C59"
                />
                <path
                    d="M47.8966 0.0515137H46.9675V39.9483H47.8966V0.0515137Z"
                    fill="#F1660B"
                />
            </g>
            <defs>
                <clipPath id="clip0_17978_43212">
                    <rect width="154.271" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default GlobalProfessionalsLogo;
