import { mobileStyles } from 'types/displayTypeStyles';
import { styled } from '@mui/material';

import { Popper } from '@mui/material';

export const CustomPopper = styled(Popper)(() => ({
    zIndex: '9999',

    [mobileStyles]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '0px 2%',
        top: '2px!important',
    },
}));
