import { theme } from 'theme/theme';
import { IconProps } from './types';

const RightArrow: React.FC<IconProps> = ({
    width,
    height = '100%',
    color = theme.colors.primaryColor,
    style,
}) => {
    return (
        <svg
            style={style}
            width={width}
            height={height}
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.969712 0.227402C0.829109 0.368048 0.750122 0.558779 0.750122 0.757652C0.750122 0.956525 0.829109 1.14726 0.969712 1.2879L4.68221 5.0004L0.969712 8.7129C0.833093 8.85435 0.757497 9.04381 0.759206 9.24045C0.760915 9.4371 0.839792 9.62521 0.978848 9.76427C1.1179 9.90332 1.30601 9.9822 1.50266 9.98391C1.69931 9.98562 1.88876 9.91002 2.03021 9.7734L6.27296 5.53065C6.41357 5.39001 6.49255 5.19928 6.49255 5.0004C6.49255 4.80153 6.41357 4.6108 6.27296 4.47015L2.03021 0.227402C1.88957 0.0867991 1.69884 0.0078125 1.49996 0.0078125C1.30109 0.0078125 1.11036 0.0867991 0.969712 0.227402Z"
                fill={color}
            />
        </svg>
    );
};

export default RightArrow;
