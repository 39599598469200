import SocialIcons, { SocialIconsType } from '../SocialIcons/SocialIcons';
import { VerticalSectionType } from '../VerticalSection/VerticalSection';
import { MobileContentType } from '../MobileContent/MobileContent';
import PageLink, { PageLinkType } from '../PageLink/PageLink';
import { EmailLinkType } from '../EmailLink/EmailLink';
import Text, { TextType } from '../Text/Text';
import MobileContent from '../MobileContent';
import EmailLink from '../EmailLink';
import VerticalSection from '../VerticalSection';

export type ConstructorProps =
    | TextType
    | VerticalSectionType
    | EmailLinkType
    | PageLinkType
    | SocialIconsType
    | MobileContentType;

const Constructor: React.FC<ConstructorProps> = (el) => {
    switch (el.type) {
        case 'verticalSection':
            return <VerticalSection {...el} />;

        case 'text':
            return <Text {...el} />;

        case 'email':
            return <EmailLink {...el} />;

        case 'link':
            return <PageLink {...el} />;

        case 'socialIcons':
            return <SocialIcons {...el} />;

        case 'mobileContent':
            return <MobileContent {...el} />;

        default: {
            return null;
        }
    }
};

export default Constructor;
