import useEnvironment from 'hooks/useEnvironment';
import { StyledAnchor } from './EmailLink.styled';
import { theme } from 'theme/theme';

const { white, primaryColor } = theme.colors;

export interface EmailLinkType {
    type: 'email';
    title: string;
    href: string;
}

const EmailLink: React.FC<EmailLinkType> = ({ title, href }) => {
    const { isGloPros } = useEnvironment();

    return (
        <StyledAnchor
            sx={{ color: isGloPros ? primaryColor : white }}
            href={href}>
            {title}
        </StyledAnchor>
    );
};

export default EmailLink;
