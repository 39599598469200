import { styled } from '@mui/material';

export const Wrapper = styled('div')(() => ({
    width: '100%',
    padding: '16px 32px',
    display: 'flex',
    alignItems: 'center',
    gap: '37px',
}));

export const ButtonsSection = styled('div')({
    display: 'flex',
    alignItems: 'center',
});
