import { useRouter } from 'next/router';
import { Wrapper } from './Footer.styled';
import GlobalProfessionalsFooter from './GlobalProfessionals';
import GloProsFooter from './GloPros';
import useEnvironment from 'hooks/useEnvironment';

const noPaddingUrls = ['talent-referral', 'sign-up', 'profile-invitations'];

const Footer = () => {
    const { pathname } = useRouter();
    const { isGloPros, isGlobalProfessionals } = useEnvironment();

    const withoutPadding = noPaddingUrls.some((url) => pathname.includes(url));

    const FooterBody = () => {
        if (isGloPros) return <GloProsFooter />;

        if (isGlobalProfessionals) return <GlobalProfessionalsFooter />;

        return null;
    };

    return (
        <Wrapper $withoutPadding={withoutPadding}>
            <FooterBody />
        </Wrapper>
    );
};

export default Footer;
