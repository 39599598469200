import { styled } from '@mui/material';

export const StyledUl = styled('ul')(() => ({
    li: {
        padding: '9px 23px',
    },

    '& li:first-of-type': {
        paddingTop: '17px',
    },

    '& li:last-of-type': {
        paddingBottom: '17px',
    },
}));

export const StyledLi = styled('li')(({ theme }) => ({
    width: '100%',

    '&:hover': {
        backgroundColor: theme.colors.primaryColor_4,
        cursor: 'pointer',
    },
}));

export const LiContentWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '11px',
}));
