import { styled } from '@mui/material';

export const PopoverBody = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    gap: '120px',
    padding: '16px 80px',
    background: 'white',
    width: '100%',
});
