import { intersection } from 'lodash';
import { useUserData } from 'state/userData';

const talentGroups = ['Professional', 'Partner'];
const clientGroups = ['Client'];
const expertGroups = ['Support Expert'];

const useUserType = () => {
    const { user } = useUserData();
    const { type, typeGroups } = user;

    const withGroups = !!typeGroups?.length;

    const types = {
        isTalent: type === 'talent',
        isClient: type === 'client',
        isExpert: type === 'expert',
    };

    const groups = {
        isTalent: !!intersection(talentGroups, typeGroups).length,
        isClient: !!intersection(clientGroups, typeGroups).length,
        isExpert: !!intersection(expertGroups, typeGroups).length,
    };

    return withGroups ? groups : types;
};

export default useUserType;
