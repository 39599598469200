import { IconProps } from './types';

const SearchIcon: React.FC<IconProps> = ({
    width,
    height,
    color = 'black',
    style,
}) => {
    return (
        <svg
            style={style}
            width={width}
            height={height}
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5239 12.4675L11.8111 9.925C12.6864 8.87684 13.1613 7.58249 13.1595 6.25C13.1595 5.06331 12.7852 3.90328 12.0838 2.91658C11.3824 1.92989 10.3855 1.16085 9.2192 0.706725C8.05286 0.2526 6.76946 0.13378 5.53128 0.365291C4.2931 0.596802 3.15576 1.16825 2.26309 2.00736C1.37041 2.84648 0.762493 3.91558 0.516205 5.07946C0.269916 6.24335 0.39632 7.44975 0.879433 8.5461C1.36255 9.64246 2.18067 10.5795 3.23034 11.2388C4.28002 11.8981 5.5141 12.25 6.77654 12.25C8.19408 12.2517 9.57105 11.8052 10.6861 10.9825L13.3909 13.5325C13.4651 13.6028 13.5533 13.6586 13.6505 13.6967C13.7478 13.7347 13.8521 13.7544 13.9574 13.7544C14.0627 13.7544 14.167 13.7347 14.2642 13.6967C14.3615 13.6586 14.4497 13.6028 14.5239 13.5325C14.5987 13.4628 14.658 13.3798 14.6985 13.2884C14.739 13.197 14.7599 13.099 14.7599 13C14.7599 12.901 14.739 12.803 14.6985 12.7116C14.658 12.6202 14.5987 12.5372 14.5239 12.4675ZM1.9893 6.25C1.9893 5.35999 2.27007 4.48996 2.7961 3.74994C3.32212 3.00992 4.06979 2.43314 4.94454 2.09254C5.81929 1.75195 6.78185 1.66284 7.71048 1.83647C8.63911 2.0101 9.49212 2.43869 10.1616 3.06802C10.8311 3.69736 11.2871 4.49918 11.4718 5.3721C11.6565 6.24501 11.5617 7.14981 11.1994 7.97208C10.837 8.79435 10.2234 9.49715 9.43618 9.99162C8.64892 10.4861 7.72336 10.75 6.77654 10.75C5.50688 10.75 4.28923 10.2759 3.39145 9.43198C2.49367 8.58807 1.9893 7.44348 1.9893 6.25Z"
                fill={color}
            />
        </svg>
    );
};

export default SearchIcon;
