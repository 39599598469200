import { SearchSelect } from 'types/componentTypes';
import {
    Wrapper,
    MenuSearchField,
    SearchFieldContent,
    Type,
    Description,
    StyledButton,
    PopoverBody,
} from './SelectInput.styled';
import { ClickAwayListener, MenuItem, Popper } from '@mui/material';
import { useState } from 'react';
import ProfessionalsIcon from 'components/SVGIcons/ProfessionalsIcon';
import SimpleBagIcon from 'components/SVGIcons/SimpleBagIcon';
import { theme } from 'theme/theme';
import RightArrow from 'components/SVGIcons/RightArrow';
import { useFormContext } from 'react-hook-form';
import { useFormValues } from 'hooks/useFormValues';
import _ from 'lodash';

const iconProps = {
    color: theme.colors.primaryColor1,
    width: 24,
};

const SelectInput: React.FC<SearchSelect> = (input) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const formValues = useFormValues();
    const searchType = formValues.searchType;

    const { setValue } = useFormContext();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const selectHandler = (value: string) => {
        setValue('searchType', value);
        setAnchorEl(null);
    };

    return (
        <Wrapper>
            <StyledButton onClick={handleClick}>
                {_.capitalize(searchType)}
                <RightArrow
                    width={8}
                    style={{
                        transform: open ? 'rotate(270deg)' : 'rotate(90deg)',
                    }}
                />
            </StyledButton>

            <Popper open={open} anchorEl={anchorEl} sx={{ zIndex: 99 }}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <PopoverBody>
                        {input.values.map((item) => {
                            switch (item.id) {
                                case 'professionals':
                                    return (
                                        <MenuItem
                                            key={item.id}
                                            value={item.id}
                                            sx={{
                                                padding: '12px 22px 6px 22px',
                                            }}
                                            onClick={() =>
                                                selectHandler(item.id)
                                            }>
                                            <MenuSearchField>
                                                <ProfessionalsIcon
                                                    {...iconProps}
                                                />

                                                <SearchFieldContent>
                                                    <Type>{item.name}</Type>
                                                    <Description>
                                                        Search and hire
                                                        professionals
                                                    </Description>
                                                </SearchFieldContent>
                                            </MenuSearchField>
                                        </MenuItem>
                                    );

                                case 'vacancies':
                                    return (
                                        <MenuItem
                                            key={item.id}
                                            value={item.id}
                                            sx={{
                                                padding: '6px 22px 12px 22px',
                                            }}
                                            onClick={() =>
                                                selectHandler(item.id)
                                            }>
                                            <MenuSearchField>
                                                <SimpleBagIcon {...iconProps} />

                                                <SearchFieldContent>
                                                    <Type>{item.name}</Type>
                                                    <Description>
                                                        Apply to vacancies
                                                        posted by clients
                                                    </Description>
                                                </SearchFieldContent>
                                            </MenuSearchField>
                                        </MenuItem>
                                    );
                            }
                        })}
                    </PopoverBody>
                </ClickAwayListener>
            </Popper>
        </Wrapper>
    );
};

export default SelectInput;
