import { styled } from '@mui/material';

export const InputTitle = styled('h4')(({ theme }) => ({
    ...theme.fonts.body_1,
    color: theme.colors.primaryText,
}));

export const StyledInput = styled('input')(({ theme }) => ({
    ...theme.fonts.body_2,
    border: 'none',
    color: theme.colors.secondaryText,
    background: 'transparent',
    outline: 'none',
}));
