import { PopoverBody } from './DropDownHeader.styled';
import ExpandMoreHeaderIcon from 'components/SVGIcons/ExpandMoreHeaderIcon';
import { theme } from 'theme/theme';
import PopoverButton from '../PopoverButton';
import { PopoverButtonProps } from '../PopoverButton/PopoverButton';
import HeaderButton from '../HeaderButton';
import {
    usePopupState,
    bindHover,
    bindPopover,
} from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

const { primaryColor, primaryText } = theme.colors;

interface Props {
    title: string;
    content: PopoverButtonProps[];
}

const DropDownHeader: React.FC<Props> = ({ title, content }) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: `header${title}`,
    });

    const { isOpen, close, setOpen } = popupState;

    return (
        <>
            <HeaderButton
                {...bindHover(popupState)}
                isOpened={popupState.isOpen}
                title={title}
                onClick={() => setOpen(!isOpen)}>
                <ExpandMoreHeaderIcon
                    width={9}
                    height={12}
                    isCursorPointer
                    rotate={isOpen}
                    color={isOpen ? primaryColor : primaryText}
                />
            </HeaderButton>

            <HoverPopover
                {...bindPopover(popupState)}
                sx={{ pointerEvents: 'none' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        paddingTop: '20px',
                        width: '100vw',
                        boxShadow: 'none',
                        background: 'transparent',
                    },
                }}
                onMouseLeave={close}>
                <PopoverBody>
                    {content.map((props, idx) => (
                        <PopoverButton
                            key={idx}
                            {...props}
                            handleClose={close}
                        />
                    ))}
                </PopoverBody>
            </HoverPopover>
        </>
    );
};

export default DropDownHeader;
