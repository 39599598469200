import { styled } from '@mui/material';
import { tabletStyles } from 'types/displayTypeStyles';

export const Wrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',

    [tabletStyles]: {
        flexDirection: 'column',
    },
});
