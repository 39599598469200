import { globalProfessionalsFooterSchema } from './schema';
import Constructor from '../Components/Constructor';
import { Content, Background } from './GlobalProfessionalsFooter.styled';

const GlobalProfessionalsFooter = () => {
    const schema = globalProfessionalsFooterSchema();

    return (
        <Background>
            <Content>
                {schema.map((el, idx) => (
                    <Constructor key={idx} {...el} />
                ))}
            </Content>
        </Background>
    );
};

export default GlobalProfessionalsFooter;
