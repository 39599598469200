import { IconProps } from './types';

const SimpleBagIcon: React.FC<IconProps> = ({
    width,
    height,
    color = 'black',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.903 3H12.7653V2.375C12.7632 1.87835 12.5642 1.40263 12.2116 1.05145C11.8589 0.700261 11.3813 0.502057 10.8826 0.5H7.11731C6.61863 0.502057 6.14097 0.700261 5.78835 1.05145C5.43573 1.40263 5.23672 1.87835 5.23465 2.375V3H2.0969C1.76403 3 1.44478 3.1317 1.20941 3.36612C0.97403 3.60054 0.841797 3.91848 0.841797 4.25V14.25C0.841797 14.5815 0.97403 14.8995 1.20941 15.1339C1.44478 15.3683 1.76403 15.5 2.0969 15.5H15.903C16.2359 15.5 16.5551 15.3683 16.7905 15.1339C17.0259 14.8995 17.1581 14.5815 17.1581 14.25V4.25C17.1581 3.91848 17.0259 3.60054 16.7905 3.36612C16.5551 3.1317 16.2359 3 15.903 3ZM6.48976 2.375C6.48976 2.20924 6.55587 2.05027 6.67356 1.93306C6.79125 1.81585 6.95087 1.75 7.11731 1.75H10.8826C11.0491 1.75 11.2087 1.81585 11.3264 1.93306C11.444 2.05027 11.5102 2.20924 11.5102 2.375V3H6.48976V2.375ZM15.903 4.25V7.5C13.7854 8.64979 11.4118 9.25152 8.99996 9.25C6.58811 9.25152 4.21449 8.64979 2.0969 7.5V4.25H15.903ZM15.903 14.25H2.0969V8.90625C4.24549 9.95564 6.60692 10.5008 8.99996 10.5C11.3931 10.5017 13.7547 9.95643 15.903 8.90625V14.25Z"
                fill={color}
            />
        </svg>
    );
};

export default SimpleBagIcon;
