import { StyledP } from './Text.styled';

export interface TextType {
    type: 'text';
    title: string;
}

const Text: React.FC<TextType> = ({ title }) => {
    return <StyledP>{title}</StyledP>;
};

export default Text;
