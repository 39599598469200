import { gloProsFooterSchema } from './schema';
import Constructor from '../Components/Constructor';
import { Content, Background, AllRightsText } from './GloProsFooter.styled';
import dayjs from 'dayjs';

const GloProsFooter = () => {
    const schema = gloProsFooterSchema();
    const currentYear = dayjs().year();

    return (
        <Background>
            <Content>
                {schema.map((el, idx) => (
                    <Constructor key={idx} {...el} />
                ))}
            </Content>

            <AllRightsText>
                © {currentYear} GloPros. All rights reserved
            </AllRightsText>
        </Background>
    );
};

export default GloProsFooter;
