import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';
import { mobileStyles } from 'types/displayTypeStyles';

export const LeftInputContainer = styled(
    'div',
    transientOptions
)<{ $isLoggedIn: boolean }>(({ theme, $isLoggedIn }) => ({
    width: $isLoggedIn ? '42%' : '26%',
    borderRadius: '0px 50px 50px 0px',
    height: '100%',
    borderRight: $isLoggedIn
        ? `1px solid ${theme.colors.dividerColor}`
        : 'none',
    display: 'flex',
    flexDirection: 'column',
    padding: $isLoggedIn ? '16px 20px 16px 44px' : '16px 0px',
    justifyContent: 'space-between',

    position: 'relative',

    [mobileStyles]: {
        borderRadius: '0px',
        borderRight: 'none',
        borderBottom: `1px solid ${theme.colors.dividerColor}`,
        width: '100%',
        padding: '18px 20px',
    },
}));

export const RightInputWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0px',
    flexGrow: '1',
    gap: '25px',

    [mobileStyles]: {
        padding: '18px 20px',
        gap: '25px',
        width: '100%',
    },
}));

export const RightInputContainer = styled('div')(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: '1',

    [mobileStyles]: {
        flexGrow: '1',
    },
}));
