import CustomLink from 'components/CustomLink';
import { Description, Title, Wrapper, Content } from './PopoverButton.styled';
import { IconProps } from 'components/SVGIcons/types';
import { theme } from 'theme/theme';

const { secondaryColor_20 } = theme.colors;
const iconProps = { width: 20, height: 20, color: secondaryColor_20 };

export interface PopoverButtonProps {
    title: string;
    Icon: React.FC<IconProps>;
    description: string;
    url: string;
    handleClose?: () => void;
}

const PopoverButton: React.FC<PopoverButtonProps> = ({
    title,
    Icon,
    description,
    url,
    handleClose,
}) => {
    return (
        <CustomLink href={url}>
            <Wrapper onClick={handleClose}>
                <Content>
                    <Title>
                        <Icon {...iconProps} />
                        {title}
                    </Title>

                    <Description>{description}</Description>
                </Content>
            </Wrapper>
        </CustomLink>
    );
};

export default PopoverButton;
