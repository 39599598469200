import { useRouter } from 'next/router';
import { useDisplayType } from 'hooks/useDisplayType';
import { ComponentHeader } from 'types/componentTypes';
import HeaderSM from './HeaderSM';
import HeaderLG from './HeaderLG';

const noBorderPages = ['sign-up', 'profile-invitations', 'tp/'];

const GloProsHeader: React.FC<ComponentHeader> = (props) => {
    const { isMobile } = useDisplayType();
    const { asPath } = useRouter();
    const hasBorder = !noBorderPages.some((str) => asPath.includes(str));

    const modifiedProps = { ...props, withBorder: hasBorder };

    return isMobile ? (
        <HeaderSM {...modifiedProps} />
    ) : (
        <HeaderLG {...modifiedProps} />
    );
};

export default GloProsHeader;
