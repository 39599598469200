import GlobalProfessionalsLogo from 'components/SVGIcons/GlobalProfessionalsLogo';
import { ButtonsSection, Wrapper } from './GlobalProfessionalsHeader.styled';
import DropDownHeader from './components/DropDownHeader';
import { companiesData, contactUsData, professionalsData } from './buttonsMap';
import HeaderButton from './components/HeaderButton';
import CustomLink from 'components/CustomLink';

const GlobalProfessionalsHeader = () => {
    return (
        <Wrapper>
            <CustomLink href="/">
                <GlobalProfessionalsLogo width={154} isCursorPointer />
            </CustomLink>

            <ButtonsSection>
                <DropDownHeader {...professionalsData} />

                <DropDownHeader {...companiesData} />

                <HeaderButton title="About Us" url="/about-us/" />

                <DropDownHeader {...contactUsData} />
            </ButtonsSection>
        </Wrapper>
    );
};

export default GlobalProfessionalsHeader;
