import { mobileStyles } from 'types/displayTypeStyles';
import { Popover } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Avatar = styled('img')(() => ({
    objectFit: 'cover',
    borderRadius: '200px 16.4px 200px 200px',
    width: '36px',
    height: '36px',
    cursor: 'pointer',
    marginLeft: '20px',

    [mobileStyles]: {
        marginRight: '20px',
        width: '24px',
        height: '24px',
    },
}));

export const CustomPopover = styled(Popover)(() => ({
    marginTop: '10px',

    '& .MuiPaper-root': {
        background: 'transparent',
        boxShadow: ' 0px 0px 5px rgba(17, 24, 33, 0.3)',
        borderRadius: '60px 10px 10px 10px',
    },
}));

export const PopoverBody = styled('div')(() => ({
    width: '235px',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0',
    alignItems: 'center',
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '200px 15px 200px 200px',
    width: '144px',
    height: '138px',
    padding: '5px',
    display: 'flex',
    background: theme.colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    filter: 'drop-shadow(0px 0px 5px rgba(17, 24, 33, 0.3))',
}));

export const Image = styled('img')(() => ({
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: '200px 15px 200px 200px',
}));

export const UserName = styled('h3')(({ theme }) => ({
    ...theme.fonts.body_1,
    marginTop: '10px',
    textAlign: 'center',

    '&:empty': {
        display: 'none',
    },
}));

export const UserType = styled('div')(({ theme }) => ({
    ...theme.fonts.caption,
    position: 'absolute',
    top: '-5px',
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px',
    marginTop: '5px',
    background: theme.colors.primaryColor,
    borderRadius: '0px 12px 0px 50px',
    color: theme.colors.white,
    textAlign: 'center',
    width: '64%',

    '&:empty': {
        display: 'none',
    },
}));

export const StyledButton = styled('button')(({ theme, disabled }) => ({
    ...theme.fonts.body_3,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 24px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    border: 'none',
    background: 'transparent',
    color: disabled ? theme.colors.dividerColor : theme.colors.secondaryText,
    '&:hover': {
        background: theme.colors.primaryColor_4,
    },
}));

export const LogOutButton = styled('button')(({ theme }) => ({
    ...theme.fonts.body_3,
    padding: '5px 38px',
    color: theme.colors.primaryText,
    background: 'transparent',
    border: `1px solid ${theme.colors.secondaryColor_2}`,
    borderRadius: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '6px',
    marginTop: '18px',
    cursor: 'pointer',
    '&:hover': {
        background: theme.colors.primaryColor_4,
    },
}));

export const BecomePartner = styled('p')(({ theme }) => ({
    ...theme.fonts.caption,
    color: theme.colors.primaryColor,
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: '10px',
}));

export const ItemsWrapper = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',

    '&:empty': {
        display: 'none',
    },
});
