import {
    StyledAccordion,
    StyledExpandMoreIcon,
    StyledTypographyTitle,
} from './Accordion.styled';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { CSSProperties } from 'react';

interface AccordionProps {
    title: string;
    $mainColor?: string;
    $borderNone?: boolean;
    $fontStyles?: { [key: string]: string };
    styles?: CSSProperties;
    detailsStyles?: CSSProperties;
}

const Accordion: React.FC<AccordionProps> = ({
    title,
    children,
    $mainColor,
    $borderNone,
    $fontStyles,
    styles,
    detailsStyles,
}) => {
    return (
        <StyledAccordion
            sx={styles}
            TransitionProps={{ unmountOnExit: true }}
            $mainColor={$mainColor}
            $borderNone={$borderNone}
            $fontStyles={$fontStyles}>
            <AccordionSummary
                expandIcon={<StyledExpandMoreIcon $mainColor={$mainColor} />}
                aria-controls="panel1a-content">
                <StyledTypographyTitle>{title}</StyledTypographyTitle>
            </AccordionSummary>
            <AccordionDetails style={detailsStyles}>{children}</AccordionDetails>
        </StyledAccordion>
    );
};

export default Accordion;
