import Link from 'next/link';
import { CSSProperties } from 'react';

interface CustomLinkProps {
    href?: string;
    fullWidth?: boolean;
    style?: CSSProperties;
    disabled?: boolean;
    onClick?: () => void;
}

const CustomLink: React.FC<CustomLinkProps> = ({
    children,
    href,
    fullWidth = false,
    style,
    disabled,
    onClick,
}) => {
    const styles: CSSProperties = {
        width: fullWidth ? '100%' : 'fit-content',
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...style,
    };

    if (!href)
        return (
            <div onClick={onClick} style={styles}>
                {children}
            </div>
        );

    return (
        <Link href={href} passHref={disabled} onClick={onClick}>
            <a rel="noopener noreferrer" style={{ all: 'unset', ...styles }}>
                {children}
            </a>
        </Link>
    );
};

export default CustomLink;
