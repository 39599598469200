import { IconProps } from './types';

const HomeIcon: React.FC<IconProps> = ({ width, height, color = 'black' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3226 10.5967H12.6452V5.42213C12.6448 5.25894 12.6114 5.09765 12.5473 4.94861C12.4831 4.79958 12.3895 4.6661 12.2726 4.55677L7.75645 0.306C7.54924 0.109187 7.27832 0 6.99718 0C6.71603 0 6.44511 0.109187 6.2379 0.306L1.72177 4.55677C1.60665 4.66729 1.51468 4.80113 1.45156 4.94999C1.38844 5.09884 1.35552 5.25955 1.35484 5.42213V10.5967H0.677419C0.497757 10.5967 0.325452 10.6706 0.198412 10.8022C0.0713708 10.9338 0 11.1123 0 11.2984C0 11.4844 0.0713708 11.6629 0.198412 11.7945C0.325452 11.9261 0.497757 12 0.677419 12H13.3226C13.5022 12 13.6745 11.9261 13.8016 11.7945C13.9286 11.6629 14 11.4844 14 11.2984C14 11.1123 13.9286 10.9338 13.8016 10.8022C13.6745 10.6706 13.5022 10.5967 13.3226 10.5967ZM2.70968 5.52737L7 1.48709L11.2903 5.52737V10.5967H9.03226V8.02404C9.03077 7.71437 8.91135 7.41782 8.69993 7.19885C8.48852 6.97987 8.20221 6.85618 7.90323 6.85464H6.09677C5.79779 6.85618 5.51148 6.97987 5.30007 7.19885C5.08865 7.41782 4.96923 7.71437 4.96774 8.02404V10.5967H2.70968V5.52737ZM7.67742 10.5967H6.32258V8.25792H7.67742V10.5967Z"
                fill={color}
            />
        </svg>
    );
};

export default HomeIcon;
