import { styled } from '@mui/material';

export const Wrapper = styled('div')(({ theme: { colors } }) => ({
    minWidth: '260px',
    width: 'min-content',
    cursor: 'pointer',
    borderRadius: '10px',
    height: 'fit-content',
    transition: '0.2s ease-in-out',

    '&:hover': {
        background: colors.secondaryColor_3,
    },
}));

export const Content = styled('div')({
    padding: '12px 30px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});

export const Title = styled('div')(({ theme: { fonts, colors } }) => ({
    ...fonts.body_1,
    color: colors.secondaryColor_20,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    whiteSpace: 'nowrap',
    width: 'max-content',
}));

export const Description = styled('div')(({ theme: { colors, fonts } }) => ({
    ...fonts.body_4,
    color: colors.secondaryText,
}));
