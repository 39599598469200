import { mobileStyles } from 'types/displayTypeStyles';
import { styled } from '@mui/material';

export const StyledUl = styled('ul')(() => ({
    li: {
        padding: '9px 23px',
    },

    '& li:first-of-type': {
        paddingTop: '17px',
    },

    '& li:last-of-type': {
        paddingBottom: '17px',
    },
}));

export const StyledLi = styled('li')(({ theme }) => ({
    width: '100%',

    '&:hover': {
        backgroundColor: theme.colors.primaryColor_4,
        cursor: 'pointer',
    },
}));

export const AutocompleteWindow = styled('div')(({ theme }) => ({
    width: '528px',
    border: `1px solid ${theme.colors.primaryColor}`,
    boxShadow: '0px 4px 20px rgba(17, 24, 33, 0.25)',
    borderRadius: '30px',
    maxHeight: '226px',
    top: '65px',
    overflow: 'auto',
    background: theme.colors.white,
    marginTop: '20px',

    [mobileStyles]: {
        width: '100%',
        maxWidth: '800px',
    },
}));
