import { styled } from '@mui/material';

export const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '20px 17px 24px',
    gap: '24px',
    borderBottom: `1px solid rgba(206, 214, 225, 0.5)`,
});

export const Title = styled('p')(({ theme }) => ({
    ...theme.fonts.h6_headline,
    color: 'white',
}));
