import { CSSProperties } from 'react';
import { IconProps } from './types';

interface Props extends IconProps {
    hideExpandMore?: boolean;
    rotate?: boolean;
}

const ExpandMoreHeaderIcon: React.FC<Props> = ({
    width,
    height,
    color = 'black',
    isCursorPointer,
    style,
    hideExpandMore = false,
    rotate,
}) => {
    const expandMoreStyle = {
        display: hideExpandMore ? 'none' : 'auto',
        pointerEvents: hideExpandMore ? 'none' : 'auto',
    };

    const cursorStyle = { cursor: isCursorPointer ? 'pointer' : 'auto' };
    const rotateStyles = { transform: `rotate(${rotate ? '180deg' : '0deg'})` };

    const customStyle = {
        ...style,
        ...cursorStyle,
        ...expandMoreStyle,
        ...rotateStyles,
    } as CSSProperties | undefined;

    return (
        <svg
            width={width}
            height={height}
            style={customStyle}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="2.02 3.75 8.5 5">
            <g id="Chevron down">
                <path
                    id="Icon"
                    d="M9.771 4.5L6.271 8L2.771 4.5"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

export default ExpandMoreHeaderIcon;
