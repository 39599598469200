import { IconProps } from './types';

const LogoIcon: React.FC<IconProps> = ({
    width,
    height,
    color = '#0053CD',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 243 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M55.4 51.7433V0.343262H65.7V51.7433H55.4Z"
                fill={color}
            />
            <path
                d="M74.8 47.4433C71.2 44.1433 69.5 39.9432 69.5 35.1432C69.5 30.2432 71.3 26.1433 74.8 22.8433C78.4 19.5433 82.7 17.8433 88 17.8433C93.3 17.8433 97.7 19.4433 101.2 22.8433C104.8 26.1433 106.5 30.3432 106.5 35.1432C106.5 40.0432 104.7 44.1433 101.2 47.4433C97.6 50.7433 93.3 52.4433 88 52.4433C82.7 52.4433 78.3 50.7433 74.8 47.4433ZM82.1 29.1433C80.5 30.7433 79.7 32.7432 79.7 35.1432C79.7 37.4432 80.5 39.5432 82.1 41.1432C83.7 42.7432 85.7 43.6432 87.9 43.6432C90.3 43.6432 92.2 42.7432 93.7 41.1432C95.3 39.5432 96.1 37.5432 96.1 35.1432C96.1 32.8432 95.3 30.7433 93.7 29.1433C92.1 27.5433 90.1 26.6433 87.9 26.6433C85.7 26.6433 83.7 27.4433 82.1 29.1433Z"
                fill={color}
            />
            <path
                d="M110.5 51.7432V3.04321H125C140 3.04321 147.5 8.24322 147.5 18.6432C147.5 24.1432 145.6 28.1432 141.7 30.6432C137.9 33.1432 132.1 34.4432 124.7 34.4432H121.3V51.8432H110.5V51.7432ZM121.2 25.5432H125.1C129.4 25.5432 132.4 25.0432 134.1 24.0432C135.8 23.0432 136.7 21.3432 136.7 19.0432C136.7 16.4432 135.8 14.6432 134 13.7432C132.1 12.8432 129.2 12.3432 124.9 12.3432H121.3L121.2 25.5432Z"
                fill={color}
            />
            <path
                d="M150.2 51.7433V18.6433H160.3V23.1433L160.4 23.2433C162.8 19.9433 166 18.3433 170 18.3433C170.8 18.3433 171.5 18.4433 172.1 18.6433V28.1433C171.2 27.9433 170.2 27.7433 169.3 27.7433C165.6 27.7433 162.6 29.0432 160.3 31.6432V51.6432L150.2 51.7433Z"
                fill={color}
            />
            <path
                d="M178.9 47.4433C175.3 44.1433 173.6 39.9432 173.6 35.1432C173.6 30.2432 175.4 26.1433 178.9 22.8433C182.5 19.5433 186.8 17.8433 192.1 17.8433C197.4 17.8433 201.8 19.4433 205.3 22.8433C208.9 26.1433 210.6 30.3432 210.6 35.1432C210.6 40.0432 208.8 44.1433 205.3 47.4433C201.7 50.7433 197.4 52.4433 192.1 52.4433C186.8 52.4433 182.4 50.7433 178.9 47.4433ZM186.2 29.1433C184.6 30.7433 183.8 32.7432 183.8 35.1432C183.8 37.4432 184.6 39.5432 186.2 41.1432C187.8 42.7432 189.8 43.6432 192 43.6432C194.4 43.6432 196.3 42.7432 197.8 41.1432C199.4 39.5432 200.2 37.5432 200.2 35.1432C200.2 32.8432 199.4 30.7433 197.8 29.1433C196.2 27.5433 194.2 26.6433 192 26.6433C189.8 26.6433 187.8 27.4433 186.2 29.1433Z"
                fill={color}
            />
            <path
                d="M214.9 49.7433V40.7433C219.2 43.2433 223.5 44.5433 227.9 44.5433C230.9 44.5433 232.4 43.6433 232.4 41.9433C232.4 41.4433 232.3 41.0433 232 40.7433C231.7 40.4433 231.4 40.1433 230.7 39.9433C230.1 39.7433 229.5 39.5433 228.9 39.4433C228.3 39.3433 227.5 39.1433 226.4 38.9433C225.3 38.7433 224.4 38.5433 223.6 38.3433C220.2 37.5433 217.8 36.3433 216.1 34.8433C214.5 33.3433 213.6 31.2433 213.6 28.6433C213.7 25.2433 215 22.5433 217.7 20.6433C220.4 18.7433 223.9 17.7433 228.1 17.7433C232.9 17.7433 237.2 18.6433 240.8 20.3433V29.1433C236.6 26.8433 232.5 25.5433 228.3 25.5433C225.4 25.5433 224 26.3433 224 27.8433C224 28.2433 224.1 28.7433 224.4 28.9433C224.6 29.2433 225 29.5433 225.7 29.7433C226.3 29.9433 226.8 30.1433 227.4 30.2433C228 30.3433 228.8 30.5433 229.8 30.7433C230.9 30.9433 231.8 31.1433 232.5 31.3433C235.9 32.2433 238.5 33.3433 240.2 34.8433C241.9 36.3433 242.8 38.4433 242.8 41.1433C242.7 44.7433 241.4 47.4433 238.6 49.4433C235.8 51.4433 232.3 52.4433 227.9 52.4433C223 52.4433 218.6 51.5433 214.9 49.7433Z"
                fill={color}
            />
            <path
                d="M50 20.4433H31.6C26.9 20.4433 23 25.6433 23 30.3433V30.6432H39.8C37.9 36.8433 32.1 41.3433 25.3 41.3433C16.9 41.3433 10.1 34.9433 10.4 26.2433C11.2 15.5433 21.2 11.7433 25.7 11.7433H39.9C46.2 11.7433 51.4 6.64326 51.4 0.343262H26.1C15 0.343262 5.40001 7.34326 1.70001 17.1433C0.600012 19.9433 0 22.9433 0 26.1433C0 40.1433 11.3 51.4433 25.3 51.4433C37.7 51.4433 48.1 42.4432 50.2 30.6432C50.3 30.2432 50.4 29.8433 50.4 29.3433C50.4 29.2433 50.4 29.0433 50.4 28.9433C50.4 28.8433 50.4 28.6433 50.4 28.5433C50.5 27.7433 50.5 27.0433 50.5 26.2433C50.5 26.2433 50.5 26.2433 50.5 26.1433C50.6 24.1433 50.4 22.2433 50 20.4433Z"
                fill={color}
            />
        </svg>
    );
};

export default LogoIcon;
