import { styled } from '@mui/material';

export const Button = styled('button')<{ isOpened?: boolean }>(
    ({ theme: { colors, fonts }, isOpened = false }) => ({
        ...fonts.body_1,
        color: isOpened ? colors.primaryColor : colors.primaryText,
        display: 'flex',
        alignItems: 'center',
        gap: '7px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: '6px 12px',
        borderRadius: '4px',
        transition: '0.2s ease-in-out',
    })
);
