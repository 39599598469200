export enum ComponentType {
    ordinaryCard = 'ordinary_card',
    buttonCard = 'button_card',
    image = 'image',
    images = 'images',
    richText = 'rich_text',
    internalLink = 'internal_link',
    externalLink = 'external_link',
    styledSection = 'styled_section',
    action = 'action',
    infographics = 'infographics_block',
    wrappedImage = 'wrapped_image',
    regularTabs = 'regular_tabs',
    imagedTabs = 'imaged_tabs',
    tabs = 'tabs',
    bigCards = 'big_cards_block',
    mediumCards = 'medium_cards_block',
    smallCards = 'small_cards_block',
    triangularArrowsCards = 'triangular_arrows_cards_block',
    wavyArrowsCards = 'wavy_arrows_cards_block',
    gridCards = 'grid_cards_block',
    withoutBordersCards = 'without_borders_cards_block',
    cards = 'cards_block',
    drop = 'drop_block',
    plans = 'plans',
    croppedPhotoBlock = 'cropped_photo_block',
    customIllustrationCards = 'custom_illustration_cards_block',
    charBlock = 'char_block',
    heroBlock = 'hero_block',
    partnerList = 'partner_list',
    reviewSlider = 'review_slider',
    customPartner = 'custom_partner',
    partner = 'partner',
    customReviewBlock = 'custom_review_block',
    reviewBlock = 'review_block',
    dividedBlock = 'divided_sections_block',
    dividedSection = 'divided_section',
    menuItems = 'menu_items',
    menuItem = 'menu_item',
    searchMenuItems = 'search_menu_items',
    searchMenuItem = 'search_menu_item',
    extendedSearchMenuItem = 'extended_search_menu_item',
    searchInput = 'input',
    searchInputSelect = 'select',
    profileMenuItems = 'profile_menu_items',
    profileMenuItem = 'profile_menu_item',
    profileMenuToggle = 'toggle',
    profileMenuLink = 'link',
    actionsList = 'actions_list',
    none = '',
}

export enum ComponentStyle {
    grayscale = 'grayscale',
    smallChessBlock = 'small_chess_block',
    mediumChessBlock = 'medium_chess_block',
    bigChessBlock = 'big_chess_block',
    offsetChessBlock = 'offset_chess_block',
    customIllustrationChessBlock = 'custom_illustration_chess_block',
    blank = 'blank',
    legal = 'legal',
    regular = 'regular',
    home = 'home',
    marketingHome = 'marketing_home',
    bigIllustration = 'big_illustration'
}

export enum ComponentColor {
    primary = 'primary',
    secondary = 'secondary',
}

export enum ModalTypes {
    editProfile = 'edit-profile',
    editEmploymentAndPayment = 'edit-employment-and-payment',
    editAboutMe = 'edit-about-me',
    editSkills = 'edit-skills',
    editLanguages = 'edit-languages',
    editEducation = 'edit-education',
    editExperience = 'edit-experience',
    editAvailability = 'edit-availability',
    editManagementLevel = 'edit-management-level',
    editOccupationGroups = 'edit-occupation-groups',
    editLocations = 'edit-locations',
    editCertification = 'edit-certification',
    none = '',
}
