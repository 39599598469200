import { IconProps } from '../types';

const VacancySearchIcon: React.FC<IconProps> = ({
    width,
    height = '100%',
    color = '#021B38',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.7272 7H16.5895V6.375C16.5874 5.87835 16.3884 5.40263 16.0358 5.05145C15.6832 4.70026 15.2055 4.50206 14.7068 4.5H10.9415C10.4429 4.50206 9.96519 4.70026 9.61257 5.05145C9.25995 5.40263 9.06094 5.87835 9.05887 6.375V7H5.92112C5.58824 7 5.269 7.1317 5.03363 7.36612C4.79825 7.60054 4.66602 7.91848 4.66602 8.25V18.25C4.66602 18.5815 4.79825 18.8995 5.03363 19.1339C5.269 19.3683 5.58824 19.5 5.92112 19.5H19.7272C20.0601 19.5 20.3794 19.3683 20.6147 19.1339C20.8501 18.8995 20.9823 18.5815 20.9823 18.25V8.25C20.9823 7.91848 20.8501 7.60054 20.6147 7.36612C20.3794 7.1317 20.0601 7 19.7272 7ZM10.314 6.375C10.314 6.20924 10.3801 6.05027 10.4978 5.93306C10.6155 5.81585 10.7751 5.75 10.9415 5.75H14.7068C14.8733 5.75 15.0329 5.81585 15.1506 5.93306C15.2683 6.05027 15.3344 6.20924 15.3344 6.375V7H10.314V6.375ZM19.7272 8.25V11.5C17.6096 12.6498 15.236 13.2515 12.8242 13.25C10.4123 13.2515 8.03871 12.6498 5.92112 11.5V8.25H19.7272ZM19.7272 18.25H5.92112V12.9062C8.06971 13.9556 10.4311 14.5008 12.8242 14.5C15.2173 14.5017 17.5789 13.9564 19.7272 12.9062V18.25Z"
                fill={color}
            />
        </svg>
    );
};

export default VacancySearchIcon;
