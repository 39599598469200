import { useDisplayType } from 'hooks/useDisplayType';
import Constructor from '../Constructor';
import { ConstructorProps } from '../Constructor/Constructor';
import { FooterBox, Title, Content } from './VerticalSection.styled';
import { theme } from 'theme/theme';
import Accordion from 'components/Accordion';

const { h6_headline } = theme.fonts;

export interface VerticalSectionType {
    type: 'verticalSection';
    title: string;
    content: ConstructorProps[];
    isVisible?: boolean;
}

const VerticalSection: React.FC<VerticalSectionType> = ({
    title,
    content,
    isVisible = true,
}) => {
    if (!isVisible) return null;

    const { isMobile } = useDisplayType();

    const ContentBody = content.map((el, idx) => (
        <Constructor key={idx} {...el} />
    ));

    if (isMobile)
        return (
            <Accordion title={title} styles={{ ...h6_headline }} detailsStyles={{display: 'flex', flexDirection: 'column'}}>
                {ContentBody}
            </Accordion>
        );

    return (
        <FooterBox>
            <Title>{title}</Title>

            <Content>{ContentBody}</Content>
        </FooterBox>
    );
};

export default VerticalSection;
