import Image from 'next/image';
import { Wrapper } from './SocialIcons.styled';

export interface SocialIconsType {
    type: 'socialIcons';
    content: { icon: string; href: string }[];
}

const SocialIcons: React.FC<SocialIconsType> = ({ content }) => {
    return (
        <Wrapper>
            {content.map((el, idx) => (
                <a
                    key={idx}
                    href={el.href}
                    style={{ display: 'flex', flexShrink: '0' }}>
                    <Image src={el.icon} width={35} height={35} />
                </a>
            ))}
        </Wrapper>
    );
};

export default SocialIcons;
