import SvgIcon from '@mui/material/SvgIcon';
import { CSSProperties } from 'react';

interface Props {
    width?: number;
    height?: number;
    color?: string;
    sx?: CSSProperties;
}

const CustomExpandIcon: React.FC<Props> = ({
    color,
    height = '100%',
    width = 10,
    sx,
    ...rest
}) => {
    return (
        <SvgIcon
            {...rest}
            sx={sx}
            width={width}
            height={height}
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.292893 0.292893C0.683416 -0.097631 1.31658 -0.097631 1.7071 0.292893L4.99999 3.58579L8.29288 0.292893C8.6834 -0.0976311 9.31657 -0.0976311 9.70709 0.292893C10.0976 0.683417 10.0976 1.31658 9.70709 1.70711L5.7071 5.70711C5.31657 6.09763 4.68341 6.09763 4.29289 5.70711L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683418 0.292893 0.292893Z"
                fill={color}
            />
        </SvgIcon>
    );
};

export default CustomExpandIcon;
