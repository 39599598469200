import { ComponentHeader } from 'types/componentTypes';
import GloProsHeader from './GloProps/GloProsHeader';
import GlobalProfessionalsHeader from './GlobalProfessionals';
import useEnvironment from 'hooks/useEnvironment';

const Header: React.FC<ComponentHeader> = (props) => {
    const { isGloPros, isGlobalProfessionals } = useEnvironment();

    if (isGloPros) return <GloProsHeader {...props} />;

    if (isGlobalProfessionals) return <GlobalProfessionalsHeader />;

    return null;
};

export default Header;
