import { IconProps } from '../types';

const LogOutIcon: React.FC<IconProps> = ({
    width,
    height = '100%',
    color = '#0D102B',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 13.1213V11.1213H7V8.12134L2 12.1213L7 16.1213V13.1213H16Z"
                fill={color}
            />
            <path
                d="M20 3.12134H11C9.897 3.12134 9 4.01834 9 5.12134V9.12134H11V5.12134H20V19.1213H11V15.1213H9V19.1213C9 20.2243 9.897 21.1213 11 21.1213H20C21.103 21.1213 22 20.2243 22 19.1213V5.12134C22 4.01834 21.103 3.12134 20 3.12134Z"
                fill={color}
            />
        </svg>
    );
};

export default LogOutIcon;
