import CheckBoxIcon from 'components/SVGIcons/CheckBoxIcon';
import HumanIcon from 'components/SVGIcons/HumanIcon';
import SearchInCircleIcon from 'components/SVGIcons/SearchInCircleIcon';

export const professionalsData = {
    title: 'Professionals',
    content: [
        {
            title: 'For professionals',
            Icon: CheckBoxIcon,
            description:
                'Explore how we can support your future career ambitions',
            url: '/our-expertise-professional/',
        },
        {
            title: 'Become a Partner',
            Icon: HumanIcon,
            description:
                'Apply as a partner. Together there are no limits to what we can achieve',
            url: '/become-a-partner/',
        },
        {
            title: 'Search vacancies',
            Icon: SearchInCircleIcon,
            description:
                'Check out our vacancies and find your next career opportunity today!',
            url: '/search-vacancies/',
        },
    ],
};

export const companiesData = {
    title: 'Companies',
    content: [
        {
            title: 'For companies',
            Icon: CheckBoxIcon,
            description:
                'Explore how we can support your future career ambitions',
            url: '/our-expertise-companies/',
        },
        {
            title: 'Become a Client',
            Icon: HumanIcon,
            description:
                'Let`s collaborate to find the expertise that supports your company`s growth.',
            url: '/become-a-client/',
        },
        {
            title: 'Search professionals',
            Icon: SearchInCircleIcon,
            description:
                'Start looking for the perfect candidate in our community today!',
            url: '/search-professionals/',
        },
    ],
};

export const contactUsData = {
    title: 'Contact Us',
    content: [
        {
            title: 'Contact for professionals',
            Icon: CheckBoxIcon,
            description:
                'Explore how we can support your future career ambitions',
            url: '/contact-for-professionals/',
        },
        {
            title: 'Contact for companies',
            Icon: HumanIcon,
            description:
                'Apply as a partner. Together there are no limits to what we can achieve ',
            url: '/contact-for-companies/',
        },
    ],
};
