import { styled } from '@mui/material';

export const FooterBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
}));

export const Title = styled('h6')(({ theme }) => ({
    ...theme.fonts.h6_headline,
    color: theme.colors.white,
}));

export const Content = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
});
